import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialHistoryState } from "./defaultState";
import { CLEAR_HISTORY_LIST, FETCH_HISTORY_LIST_FAILED, FETCH_HISTORY_LIST_PROGRESS, FETCH_HISTORY_LIST_SUCCESS, HistoryActions } from ".";

export const historyReducer = (
  state: IStoreState["history"] = initialHistoryState,
  action: HistoryActions,
) => {
  switch (action.type) {
    case FETCH_HISTORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.allHistory.loading = LoadState.InProgress;
        draftState.allHistory.data = [];
      });
      return newState;
    }
    case FETCH_HISTORY_LIST_SUCCESS: {
      const { data, count } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.allHistory.loading = LoadState.Loaded;
        draftState.allHistory.data = data;
        draftState.allHistory.totalRecords = count;
      });
      return newState;
    }
    case FETCH_HISTORY_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.allHistory.loading = LoadState.Failed;
        draftState.allHistory.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_HISTORY_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.allHistory.loading = LoadState.NotLoaded;
        draftState.allHistory.data = [];
        draftState.allHistory.error = null;
      });
      return newState;
    }

    // ---------------------------------- Default ----------------------------------
    default: {
      return state;
    }
  }
};
