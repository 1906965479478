import { LoadState } from "../../constants/enums";
import { ITaskCategory, ITaskCategoryState } from "./taskCategory.types";


export const initialTaskCategory: ITaskCategory = {
    category_uuid: null,
    category_name: "",
    description: "",
    status: "ACTIVE",
}

export const initialTaskCategoryState: ITaskCategoryState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    TaskCategory: initialTaskCategory,
    TaskCategoryLoading: LoadState.NotLoaded,
    error: null,
}