import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { ProductActions } from ".";
import { CLEAR_SCHEDULE_TASK_STATE, FETCH_SCHEDULE_TASK_FAILED, FETCH_SCHEDULE_TASK_LIST_FAILED, FETCH_SCHEDULE_TASK_LIST_PROGRESS, FETCH_SCHEDULE_TASK_LIST_SUCCESS, FETCH_SCHEDULE_TASK_PROGRESS, FETCH_SCHEDULE_TASK_SUCCESS } from "./scheduleTaskActions";
import { CLEAR_SCHEDULE_TASK } from "./scheduleTaskActions";
import { initialScheduleTaskState } from "./defaultState";

export const scheduleTaskReducer = (
  state: IStoreState["scheduleTask"] = initialScheduleTaskState,
  action: ProductActions,
) => {
  switch (action.type) {
    case FETCH_SCHEDULE_TASK_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SCHEDULE_TASK_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_SCHEDULE_TASK_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_SCHEDULE_TASK_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleTaskLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SCHEDULE_TASK_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.scheduleTaskLoading = LoadState.Loaded;
        draftState.scheduleTask = data;
      });
      return newState;
    }
    case FETCH_SCHEDULE_TASK_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.scheduleTaskLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SCHEDULE_TASK: {
      const newState = produce(state, (draftState) => {
        draftState.scheduleTaskLoading = LoadState.NotLoaded;
        draftState.scheduleTask = initialScheduleTaskState["scheduleTask"];
      });
      return newState;
    }
    case CLEAR_SCHEDULE_TASK_STATE: {
      return initialScheduleTaskState;
    }
    default: {
      return state;
    }
  }
};
