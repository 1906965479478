import { LoadState } from "../../constants/enums";

export interface IProductsState {
  list: IProduct[];
  totalRecords: number;
  loading: LoadState;
  product: IProduct;
  productLoading: LoadState;
  error: string | null;
}

export interface IProduct {
  product_uuid: string | null;
  product_name: string | null;
  layout_location: string | null;
  finishes: string | null;
  fabric_colour: string | null;
  metal: string | null;
  wood: string | null;
  glass: string | null;
  acrylic: string | null;
  polycarbonate: string | null;
  product_code?: string;
  product_code_prefix: string;
  product_code_suffix: string | null;
  product_description: string | null;
  eni_no: string | null;
  product_height: number | null;
  product_depth: number | null;
  product_width: number | null;
  quantity: number | null;
  product_price: number;
  total: string | null;
  weight: string | null;
  total_weight: string | null;
  volume_m3: string | null;
  total_volume_m3: string | null;
  category: string | null;
  design: string | null;
  status: string;
  create_ts?: string;
  insert_ts?: string;
}

export const initialProductsState: IProductsState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  product: {
    product_uuid: "",
    product_name: "",
    layout_location: "",
    finishes: "",
    fabric_colour: "",
    metal: "",
    wood: "",
    glass: "",
    acrylic: "",
    polycarbonate: "",
    product_code: "",
    product_code_prefix: "",
    product_code_suffix: null,
    product_description: "",
    eni_no: "",
    product_height: null,
    product_depth: null,
    product_width: null,
    quantity: null,
    product_price: 0,
    total: null,
    weight: null,
    total_weight: null,
    volume_m3: null,
    total_volume_m3: null,
    category: "",
    design: null,
    status: "ACTIVE",
  },
  productLoading: LoadState.NotLoaded,
  error: null,
};
