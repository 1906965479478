import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IHistory } from "./history.types";

//****************************** Fetch History List******************************//
export const FETCH_HISTORY_LIST_PROGRESS = "FETCH_HISTORY_LIST_PROGRESS";
export const FETCH_HISTORY_LIST_SUCCESS = "FETCH_HISTORY_LIST_SUCCESS";
export const FETCH_HISTORY_LIST_FAILED = "FETCH_HISTORY_LIST_FAILED";

export const fetchHistoryListProgress = () =>
  action(FETCH_HISTORY_LIST_PROGRESS);
export const fetchHistoryListSuccess = (data: IHistory[], count: number) =>
  action(FETCH_HISTORY_LIST_SUCCESS, { data, count });
export const fetchHistoryListFailed = (errorMessage: string) =>
  action(FETCH_HISTORY_LIST_FAILED, { errorMessage });

export const fetchHistoryListAsync =
  (
    moduleUUID: string,
    // value: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchHistoryListProgress());
        const res = await api.get(`/history/get-history?module_uuid=${moduleUUID}`,); 
        const data = res.data.data;
        dispatch(fetchHistoryListSuccess(data, res.data.count));
      } catch (err: any) {
        dispatch(fetchHistoryListFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_HISTORY_LIST = "CLEAR_HISTORY_LIST";
export const clearHistoryListAsync = () => action(CLEAR_HISTORY_LIST);

//****************************** Fetch Single History ******************************//
export const FETCH_SINGLE_HISTORY_PROGRESS = "FETCH_SINGLE_HISTORY_PROGRESS";
export const FETCH_SINGLE_HISTORY_SUCCESS = "FETCH_SINGLE_HISTORY_SUCCESS";
export const FETCH_SINGLE_HISTORY_FAILED = "FETCH_SINGLE_HISTORY_FAILED";

export const fetchSingleHistoryProgress = () =>
  action(FETCH_SINGLE_HISTORY_PROGRESS);
export const fetchSingleHistorySuccess = (history: IHistory) =>
  action(FETCH_SINGLE_HISTORY_SUCCESS, { history });
export const fetchSingleHistoryFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_HISTORY_FAILED, { errorMessage });

export const fetchSingleHistoryAsync =
  (moduleUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchSingleHistoryProgress());
        const res = await api.get(
          `/history/get-history?module_uuid=${moduleUUID}`, //need to be  replaced
        );
        const data = res.data.data;
        if (data.length > 0) {
          dispatch(fetchSingleHistorySuccess(data[0]));
        } else {
          dispatch(
            fetchSingleHistoryFailed(
              "Unfortunately, there are no records available at the moment.",
            ),
          );
        }
      } catch (err: any) {
        dispatch(fetchSingleHistoryFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_SINGLE_HISTORY = "CLEAR_SINGLE_HISTORY";
export const clearSingleHistoryAsync = () => action(CLEAR_SINGLE_HISTORY);

//****************************** Insert/Update Single History ******************************//

export const upsertSingleHistoryAsync =
  (
    comment: IHistory,
    onCallback: (isSuccess: boolean, comment?: IHistory) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const { create_ts, insert_ts, ...payload } = comment;

        const result = await api.post("/comment/upsert-comment", payload); // need to be replaced
        const responseHistory = result.data?.data;
        let message = "History is saved successfully.";
        dispatch(
          showMessage({
            type: "success",
            message: message,
            displayAs: "snackbar",
          }),
        );
        onCallback(true, responseHistory);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };
