import produce from "immer";

import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

import {
  CLEAR_DEPARTMENT,
  CLEAR_DEPARTMENT_STATE,
  DepartmentActions,
  FETCH_DEPARTMENT_FAILED,
  FETCH_DEPARTMENT_LIST_FAILED,
  FETCH_DEPARTMENT_LIST_PROGRESS,
  FETCH_DEPARTMENT_LIST_SUCCESS,
  FETCH_DEPARTMENT_PROGRESS,
  FETCH_DEPARTMENT_SUCCESS,
} from ".";
import { defaultExpense } from "../expenseModule/defaultState";
import { defaultDepartment, defaultDepartmentState } from "./defaultState";

export const departmentReducer = (
  state: IStoreState["department"] = defaultDepartmentState,
  action: DepartmentActions,
) => {
  switch (action.type) {
    case FETCH_DEPARTMENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.departmentList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_DEPARTMENT_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.departmentList.loading = LoadState.Loaded;
        draftState.departmentList.list = data;
        draftState.departmentList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_DEPARTMENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.departmentList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_DEPARTMENT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.departmentData.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_DEPARTMENT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.departmentData.loading = LoadState.Loaded;
        draftState.departmentData.data = data;
      });
      return newState;
    }
    case FETCH_DEPARTMENT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.departmentData.loading = LoadState.Failed;
        draftState.departmentData.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_DEPARTMENT: {
      const newState = produce(state, (draftState) => {
        draftState.departmentData.loading = LoadState.NotLoaded;
        draftState.departmentData.data = defaultDepartment;
      });
      return newState;
    }
    // common state clear
    case CLEAR_DEPARTMENT_STATE: {
      return defaultDepartmentState;
    }
    default: {
      return state;
    }
  }
};
