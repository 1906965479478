import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { ProductActions } from ".";
import { CLEAR_PROJECT, CLEAR_PROJECT_STATE, FETCH_PROJECT_FAILED, FETCH_PROJECT_LIST_FAILED, FETCH_PROJECT_LIST_PROGRESS, FETCH_PROJECT_LIST_SUCCESS, FETCH_PROJECT_PROGRESS, FETCH_PROJECT_SUCCESS } from "./projectActions";
import { initialProjectState } from "./defaultState";

export const projectsReducer = (
  state: IStoreState["projects"] = initialProjectState,
  action: ProductActions,
) => {
  switch (action.type) {
    case FETCH_PROJECT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PROJECT_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PROJECT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_PROJECT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.projectLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PROJECT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.projectLoading = LoadState.Loaded;
        draftState.project = data;
      });
      return newState;
    }
    case FETCH_PROJECT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.projectLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_PROJECT: {
      const newState = produce(state, (draftState) => {
        draftState.projectLoading = LoadState.NotLoaded;
        draftState.project = initialProjectState["project"];
      });
      return newState;
    }
    case CLEAR_PROJECT_STATE: {
      return initialProjectState;
    }
    default: {
      return state;
    }
  }
};
