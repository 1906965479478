import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialTaskCalenderState } from "./tasksCalender.types";
import { TasksCalenderActions } from ".";
import {
  FETCH_TASKS_CALENDER_FAILED,
  FETCH_TASKS_CALENDER_PROGRESS,
  FETCH_TASKS_CALENDER_SUCCESS,
} from "./tasksCalenderActions";

export const tasksCalenderReducer = (
  state: IStoreState["calender"] = initialTaskCalenderState,
  action: TasksCalenderActions
) => {
  switch (action.type) {
    case FETCH_TASKS_CALENDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
      });
      return newState;
    }
    case FETCH_TASKS_CALENDER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_TASKS_CALENDER_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
