import { action } from "typesafe-actions";
import { IProject, IProjectState } from "./project.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";

export const FETCH_PROJECT_LIST_PROGRESS = "FETCH_ PROJECT_LIST_PROGRESS";
export const FETCH_PROJECT_LIST_SUCCESS = "FETCH_  PROJECT_LIST_SUCCESS";
export const FETCH_PROJECT_LIST_FAILED = "FETCH_   PROJECT_LIST_FAILED";

export const fetchProjectListProgress = () =>
  action(FETCH_PROJECT_LIST_PROGRESS);
export const fetchProjectListSuccess = (
  data: IProjectState["list"],
  totalRecords: number,
) => action(FETCH_PROJECT_LIST_SUCCESS, { data, totalRecords });
export const fetchProjectListFailed = () => action(FETCH_PROJECT_LIST_FAILED);

export const fetchProjectListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchProjectListProgress());
      const searchQuery = getSearchQuery(queryParams);
      const res = await api.get(`/project/get-project${searchQuery}`);
      const data: IProject[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchProjectListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchProjectListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertProjectAsync =
  (
    data: IProject,
    onCallback: (isSuccess: boolean, project?: IProject) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, ...rest } = data;
      const res = await api.post("/project/upsert-project", rest);
      let message = "Project saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_PROJECT_PROGRESS = "FETCH_PROJECT_PROGRESS";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_FAILED = "FETCH_PROJECT_FAILED";

export const fetchProjectProgress = () => action(FETCH_PROJECT_PROGRESS);
export const fetchProjectSuccess = (data: IProject) =>
  action(FETCH_PROJECT_SUCCESS, { data });
export const fetchProjectFailed = (errorMessage: string) =>
  action(FETCH_PROJECT_FAILED, { errorMessage });

export const fetchProjectAsync =
  (projectUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchProjectProgress());
      const res = await api.get(
        `/project/get-project?project_uuid=${projectUUID}`,
      );
      const data: IProject[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchProjectSuccess(data[0]));
      } else {
        dispatch(fetchProjectFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchProjectFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_PROJECT = "CLEAR_PROJECT";
export const clearProject = () => action(CLEAR_PROJECT);
export const CLEAR_PROJECT_STATE = "CLEAR_ PROJECT_STATE";
export const clearProjectState = () => action(CLEAR_PROJECT_STATE);
