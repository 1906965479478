import { LoadState } from "../../constants/enums";
import { ICostingSheet, ICostingSheetState } from "./costingSheet.types";

export const defaultCostingSheet: ICostingSheet = {
  costing_sheet_uuid: null,
  assigned_to_uuid: "",
  assigned_to_name: "",
  approval_dc_uuid: "",
  enquiry_no: "",
  incremental_column: 0,
  approval_date: null,
  approval_uuid: null,
  discount_type: "",
  product_items: [],
  sales_executive_name: null,
  sales_executive_name_uuid: null,
  sales_executive_date: null,
  code_creation_name: null,
  code_creation_name_uuid: null,
  code_creation_date: null,
  customer_name: null,
  se_approval_name: null,
  se_approval_name_uuid: null,
  se_approval_date: null,
  final_approval_name: null,
  final_approval_name_uuid: null,
  final_approval_date: null,
  project_name: null,
  note: null,
  filePath: null,
  status: "",
};
export const defaultCostingSheetState: ICostingSheetState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  costingSheet: {
    data: defaultCostingSheet,
    loading: LoadState.NotLoaded,
    error: null,
  },
  approvalCostingSheetList: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
