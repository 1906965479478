import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialProductsState } from "./products.types";
import { ProductActions } from ".";
import {
  CLEAR_PRODUCT,
  CLEAR_PRODUCT_STATE,
  FETCH_PRODUCTS_LIST_FAILED,
  FETCH_PRODUCTS_LIST_PROGRESS,
  FETCH_PRODUCTS_LIST_SUCCESS,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_PROGRESS,
  FETCH_PRODUCT_SUCCESS,
} from "./productActions";

export const productsReducer = (
  state: IStoreState["products"] = initialProductsState,
  action: ProductActions,
) => {
  switch (action.type) {
    case FETCH_PRODUCTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCTS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PRODUCTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_PRODUCT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.productLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.productLoading = LoadState.Loaded;
        draftState.product = data;
      });
      return newState;
    }
    case FETCH_PRODUCT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.productLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_PRODUCT: {
      const newState = produce(state, (draftState) => {
        draftState.productLoading = LoadState.NotLoaded;
        draftState.product = initialProductsState["product"];
      });
      return newState;
    }
    case CLEAR_PRODUCT_STATE: {
      return initialProductsState;
    }
    default: {
      return state;
    }
  }
};
