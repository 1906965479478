import { LoadState } from "../../constants/enums";
import { IProjectTeam, IProjectTeamState } from "./projectTeam.types";

export const defaultProjectTeam: IProjectTeam = {
  project_team_uuid: null,
  project_uuid: null,
  project_name: "",
  user_uuid: null,
  user_name: null,
  status: "ACTIVE",
};

export const defaultProjectTeamState: IProjectTeamState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  ProjectTeam: defaultProjectTeam,
  ProjectTeamLoading: LoadState.NotLoaded,
  error: null,
};
