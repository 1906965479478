import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {  TaskListActions } from ".";
import { initialTaskListState } from "./defaultState";
import { CLEAR_TASK_LIST_ITEM, CLEAR_TASK_LIST_STATE, FETCH_TASK_LIST_FAILED, FETCH_TASK_LIST_PROGRESS, FETCH_TASK_LIST_SUCCESS } from "./taskListActions";

export const taskListReducer = (
  state: IStoreState["taskList"] = initialTaskListState,
  action: TaskListActions,
) => {
  switch (action.type) {
    case FETCH_TASK_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_TASK_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_TASK_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }


    case CLEAR_TASK_LIST_ITEM: {
      const newState = produce(state, (draftState) => {
        draftState.TaskListLoading = LoadState.NotLoaded;
        draftState.list = initialTaskListState["list"];
      });
      return newState;
    }
    case CLEAR_TASK_LIST_STATE: {
      return initialTaskListState;
    }
    default: {
      return state;
    }
  }
};
