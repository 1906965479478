import { LoadState } from "../../constants/enums";
import { IHistory, IHistoryState } from "./history.types";

export const initialHistory: IHistory = {
  name: null,
  history_uuid: null,
  module_name: null,
  module_uuid: null,
  module_id: null,
  module_column_name: null,
  message: null,
  created_by_uuid: null,
}

export const initialHistoryState: IHistoryState = {
  allHistory: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
  singleHistory: {
    loading: LoadState.NotLoaded,
    data: initialHistory,
    error: null,
  },
};
