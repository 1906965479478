import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { taskCategoryActions } from ".";
import { initialTaskCategoryState } from "./defaultState";
import { initialTaskListState } from "../taskList/defaultState";
import { FETCH_TASK_CATEGORY_LIST_PROGRESS, FETCH_TASK_CATEGORY_LIST_SUCCESS, FETCH_TASK_CATEGORY_LIST_FAILED, CLEAR_TASK_CATEGORY_LIST_STATE, CLEAR_TASK_CATEGORY, FETCH_TASK_CATEGORY_PROGRESS, FETCH_TASK_CATEGORY_SUCCESS, FETCH_TASK_CATEGORY_FAILED } from "./taskCategoryActions";


export const taskCategoryReducer = (
  state: IStoreState["taskCategory"] = initialTaskCategoryState,
  action: taskCategoryActions,
) => {
  switch (action.type) {
    case FETCH_TASK_CATEGORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_TASK_CATEGORY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_TASK_CATEGORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_TASK_CATEGORY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.TaskCategoryLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_TASK_CATEGORY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.TaskCategoryLoading = LoadState.Loaded;
        draftState.TaskCategory = data;
      });
      return newState;
    }
    case FETCH_TASK_CATEGORY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.TaskCategoryLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_TASK_CATEGORY: {
      const newState = produce(state, (draftState) => {
        draftState.TaskCategoryLoading = LoadState.NotLoaded;
        draftState.list = initialTaskCategoryState["list"];
      });
      return newState;
    }
    case CLEAR_TASK_CATEGORY_LIST_STATE: {
      return initialTaskCategoryState;
    }
    default: {
      return state;
    }
  }
};
