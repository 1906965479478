import { LoadState } from "../../constants/enums";
import { ITaskListItem, ITaskListState } from "./taskList.types";


export const initialTaksListItem: ITaskListItem = {
    task_uuid: null,
    type: "Project",
    type_name: "",
    type_uuid: "",
    project_manager_uuid: null,
    project_manager: null,
    title: "",
    description: null,
    due_date: null,
    upload_file: null,
    priority: "HIGH",
    category_name: null,
    category_uuid: null,
    assigned_to_name: "",
    assigned_to_uuid: "",
    time_taken: null,
    status: "TODO"
}

export const initialTaskListState: ITaskListState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    TaskListItem: initialTaksListItem,
    TaskListLoading: LoadState.NotLoaded,
    error: null,
}