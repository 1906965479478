import { action } from "typesafe-actions";
import { IBranch, IDataManagementState, IZone } from "./dataManagement.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { api } from "../../api/api";
import { AnyAction } from "redux";
import { showMessage } from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";

export const FETCH_USER_BRANCH_LIST_PROGRESS = "FETCH_USER_BRANCH_LIST";
export const FETCH_USER_BRANCH_LIST_SUCCESS = "FETCH_USER_BRANCH_LIST_SUCCESS";
export const FETCH_USER_BRANCH_LIST_FAILED = "FETCH_USER_BRANCH_LIST_FAILED";

export const fetchUserBranchListProgress = () =>
  action(FETCH_USER_BRANCH_LIST_PROGRESS);
export const fetchUserBranchListSuccess = (
  list: IDataManagementState["branchList"]["list"],
  totalRecords: number,
) => action(FETCH_USER_BRANCH_LIST_SUCCESS, { list, totalRecords });
export const fetchUserBranchListFailed = () =>
  action(FETCH_USER_BRANCH_LIST_FAILED);

export const fetchUserBranchListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchUserBranchListProgress());
      const res = await api.get(`/user/get-branch${searchQuery}`);
      const data: IDataManagementState["branchList"]["list"] = res.data.data;
      dispatch(fetchUserBranchListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchUserBranchListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_USER_BRANCH_PROGRESS = "FETCH_USER_BRANCH_PROGRESS";
export const FETCH_USER_BRANCH_SUCCESS = "FETCH_USER_BRANCH_SUCCESS";
export const FETCH_USER_BRANCH_FAILED = "FETCH_USER_BRANCH_FAILED";

export const fetchUserBranchProgress = () => action(FETCH_USER_BRANCH_PROGRESS);
export const fetchUserBranchSuccess = (data: IBranch) =>
  action(FETCH_USER_BRANCH_SUCCESS, { data });
export const fetchUserBranchFailed = (errorMessage: string) =>
  action(FETCH_USER_BRANCH_FAILED, { errorMessage });

export const fetchUserBranchAsync =
  (branchUUId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUserBranchProgress());
      const res = await api.get(`/user/get-branch?branch_uuid=${branchUUId}`);
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchUserBranchSuccess(data[0]));
      } else {
        dispatch(
          fetchUserBranchFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchUserBranchFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertUserBranchAysnc =
  (
    data: IBranch,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, ...rest } = data;
      await api.post("/user/upsert-branch", rest);
      dispatch(
        showMessage({
          type: "success",
          message: "User branch is saved succesfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
export const FETCH_USER_ZONE_LIST_PROGRESS = "FETCH_USER_ZONE_LIST";
export const FETCH_USER_ZONE_LIST_SUCCESS = "FETCH_USER_ZONE_LIST_SUCCESS";
export const FETCH_USER_ZONE_LIST_FAILED = "FETCH_USER_ZONE_LIST_FAILED";

export const fetchUserZoneListProgress = () =>
  action(FETCH_USER_ZONE_LIST_PROGRESS);
export const fetchUserZoneListSuccess = (
  list: IDataManagementState["zoneList"]["list"],
  totalRecords: number,
) => action(FETCH_USER_ZONE_LIST_SUCCESS, { list, totalRecords });
export const fetchUserZoneListFailed = () =>
  action(FETCH_USER_ZONE_LIST_FAILED);

export const fetchUserZoneListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchUserZoneListProgress());
      const res = await api.get(`/user/get-zone${searchQuery}`);
      const data: IDataManagementState["zoneList"]["list"] = res.data.data;
      dispatch(fetchUserZoneListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchUserZoneListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_USER_ZONE_PROGRESS = "FETCH_USER_ZONE_PROGRESS";
export const FETCH_USER_ZONE_SUCCESS = "FETCH_USER_ZONE_SUCCESS";
export const FETCH_USER_ZONE_FAILED = "FETCH_USER_ZONE_FAILED";

export const fetchUserZoneProgress = () => action(FETCH_USER_ZONE_PROGRESS);
export const fetchUserZoneSuccess = (data: IZone) =>
  action(FETCH_USER_ZONE_SUCCESS, { data });
export const fetchUserZoneFailed = (errorMessage: string) =>
  action(FETCH_USER_ZONE_FAILED, { errorMessage });

export const fetchUserZoneAsync =
  (
    zone_uuid: string,
    page: number,
    rowsInPerPage: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUserBranchProgress());
      const res = await api.get(
        `/user/get-zone?zone_uuid=${zone_uuid}&pageNo=${page}&itemPerPage=${rowsInPerPage}`,
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchUserZoneSuccess(data[0]));
      } else {
        dispatch(
          fetchUserZoneFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchUserZoneFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertUserZoneAysnc =
  (
    data: IZone,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post("/user/upsert-zone", data);
      dispatch(
        showMessage({
          type: "success",
          message: "User branch is saved succesfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_USER_BRANCH = "CLEAR_USER_BRANCH";
export const CLEAR_USER_BRANCH_STATE = "CLEAR_USER_BRANCH_STATE";
export const clearUserBranch = () => action(CLEAR_USER_BRANCH);
export const clearUserBranchState = () => action(CLEAR_USER_BRANCH_STATE);
export const CLEAR_USER_ZONE = "CLEAR_USER_ZONE";
export const CLEAR_USER_ZONE_STATE = "CLEAR_USER_ZONE_STATE";
export const clearUserZone = () => action(CLEAR_USER_ZONE);
export const clearUserZoneState = () => action(CLEAR_USER_ZONE_STATE);
