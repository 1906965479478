import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IManageSite, IManageSiteState } from "./manageSite.types";
import { uploadFile, uploadImage } from "../../helpers/uploadFile";
import { useLoggedInUserInfo } from "../../hooks";
import { lastIndexOf } from "lodash";

// export const FETCH_MANAGE_SITE_LIST_PROGRESS = "FETCH_MANAGE_SITE_LIST_PROGRESS";
// export const FETCH_MANAGE_SITE_LIST_SUCCESS = "FETCH_MANAGE_SITE_LIST_SUCCESS";
// export const FETCH_MANAGE_SITE_LIST_FAILED = "FETCH_MANAGE_SITE_LIST_FAILED";

// export const fetchManageSiteListProgress = () =>
//   action(FETCH_MANAGE_SITE_LIST_PROGRESS);
// export const fetchManageSiteListSuccess = (
//   data: IManageSiteState["list"],
//   totalRecords: number,
// ) => action(FETCH_MANAGE_SITE_LIST_SUCCESS, { data, totalRecords });
// export const fetchManageSiteListFailed = () => action(FETCH_MANAGE_SITE_LIST_FAILED);

// export const fetchManageSiteListAsync =
//   (
//     queryParams: IQueryParams
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//     async (dispatch, getState) => {
//       try {
//         dispatch(fetchManageSiteListProgress());
//         const searchQuery = getSearchQuery(queryParams);
//         const res = await api.get(`/category/get-category${searchQuery}`);
//         const data: IManageSite[] = res.data.data;
//         const totalRecords = res.data.totalRecords;

//         dispatch(fetchManageSiteListSuccess(data, totalRecords));
//       } catch (err: any) {
//         dispatch(fetchManageSiteListFailed());
//         dispatch(
//           showMessage({
//             type: "error",
//             message: err.response.data.message,
//             displayAs: "snackbar",
//           }),
//         );
//       }
//     };

export const upsertManageSiteAsync = (
  data: IManageSite,
  file: any | null,
  onCallback: (isSuccess: boolean, ManageSite?: IManageSite) => void
): ThunkAction<void, IStoreState, {}, AnyAction> => async (dispatch, getState) => {
  try {
    if (file && !file.type.startsWith('image/')) {
      dispatch(
        showMessage({
          type: "error",
          displayAs: "snackbar",
          message: "File must be an image.",
        })
      );
      onCallback(false);
      return
    }

    // if (data.logo === null && data.site_name === null) {
    //   dispatch(
    //     showMessage({
    //       type: "error",
    //       displayAs: "snackbar",
    //       message: "Logo field can't be null.",
    //     })
    //   );
    //   onCallback(false);
    //   return
    // }
    const { create_ts, insert_ts, rowId, created_by_uuid, modified_by_uuid, ...rest } = data;
    let payload;
    if (file) {
      const path = await uploadImage(file);
      payload = {
        ...rest,
        logo: path,
        site_name: null,
      };
    } else {
      payload = { ...rest, logo: null };
    }

    const res = await api.post("/user/upsert-manage-site", payload);
    let message = "Saved successfully. Please refresh the page";
    onCallback(true, res.data.data);
    dispatch(
      showMessage({
        type: "success",
        displayAs: "snackbar",
        message: message,
      })
    );
  } catch (err: any) {
    onCallback(false);
    dispatch(
      showMessage({
        type: "error",
        message: err.response?.data?.message || "An error occurred",
        displayAs: "snackbar",
      })
    );
  }
};


//************ Fetch Single Data **************

export const FETCH_MANAGE_SITE_PROGRESS = "FETCH_MANAGE_SITE_PROGRESS";
export const FETCH_MANAGE_SITE_SUCCESS = "FETCH_MANAGE_SITE_SUCCESS";
export const FETCH_MANAGE_SITE_FAILED = "FETCH_MANAGE_SITE_FAILED";

export const fetchManageSiteProgress = () =>
  action(FETCH_MANAGE_SITE_PROGRESS);
export const fetchManageSiteSuccess = (
  data: IManageSite) => action(FETCH_MANAGE_SITE_SUCCESS, { data });
export const fetchManageSiteFailed = (errorMessage: string) => action(FETCH_MANAGE_SITE_FAILED, { errorMessage });

export const fetchManageSiteAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch) => {
      try {
        dispatch(fetchManageSiteProgress());
        const res = await api.get(`/user/get-manage-site`);
        const data: IManageSite = res.data.data[0];
        dispatch(fetchManageSiteSuccess(data));
      } catch (err: any) {
        dispatch(fetchManageSiteFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_MANAGE_SITE = "CLEAR_MANAGE_SITE";
export const clearManageSite = () => action(CLEAR_MANAGE_SITE);
export const CLEAR_MANAGE_SITE_STATE = "CLEAR_MANAGE_SITE_STATE";
export const clearManageSiteState = () => action(CLEAR_MANAGE_SITE_STATE);
