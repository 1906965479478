import { LoadState } from "../../constants/enums";
import { IScheduleTask, IScheduleTaskState } from "./scheduleTask.types";

export const initialScheduleTask: IScheduleTask = {
    task_definition_uuid: null,
    category_name: null,
    category_uuid: null,
    task_type: "time",
    task_time: null,
    task_date: null,
    task_day_of_week: null,
    task_weekdays: [],
    task_day_of_month: null,
    task_month: null,
    task_year: null,
    task_interval: null,
    type: "Project",
    type_name: "",
    type_uuid: "",
    project_manager_uuid: null,
    project_manager: null,
    title: "",
    description: null,
    upload_file: null,
    priority: "HIGH",
    assigned_to_name: "",
    assigned_to_uuid: "",
    status: "TODO"
}

export const initialScheduleTaskState: IScheduleTaskState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    scheduleTask: initialScheduleTask,
    scheduleTaskLoading: LoadState.NotLoaded,
    error: null,
}