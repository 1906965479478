import produce from "immer";
import { BankDetailsActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultProjectTeamState } from "./defaultState";
import { CLEAR_PROJECT_TEAM, CLEAR_PROJECT_TEAM_STATE, FETCH_PROJECT_TEAM_LIST_FAILED, FETCH_PROJECT_TEAM_LIST_PROGRESS, FETCH_PROJECT_TEAM_LIST_SUCCESS } from "./projectTeamActions";


export const projectTeamReducer = (
  state: IStoreState["projectTeam"] = defaultProjectTeamState,
  action: BankDetailsActions,
) => {
  switch (action.type) {
    case FETCH_PROJECT_TEAM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PROJECT_TEAM_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PROJECT_TEAM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }    

    case CLEAR_PROJECT_TEAM: {
      const newState = produce(state, (draftState) => {
        draftState.ProjectTeamLoading = LoadState.NotLoaded;
        draftState.ProjectTeam = defaultProjectTeamState["ProjectTeam"];
      });
      return newState;
    }
    case CLEAR_PROJECT_TEAM_STATE: {
      return defaultProjectTeamState;
    }
    default: {
      return state;
    }
  }
};
