import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { MODULE_IDS } from "../constants/enums";
import Loadable from "../layout/fullLayout/loadable/loadable";
import { BeforeProfile } from "../views/Profile/UsersList/UsersList";
import { OOPSError } from "../views/authentication/OopsError";
import { BranchList } from "../views/dataManagement/userBranch/UserBranchList";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { ProtectedRoute } from "./ProtectedRoutes";
import { PublicRoute } from "./PublicRoute";
import { ZoneList } from "../views/dataManagement/userZone/ZoneList";
import { ManageUserBranch } from "../views/dataManagement/userBranch/ManageUserBranch";

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() =>
    import("../layout/fullLayout/FullLayout").then(({ FullLayout }) => ({
      default: FullLayout,
    })),
  ),
);
const BlankLayout = Loadable(
  lazy(() => import("../layout/blankLayout/BlankLayout")),
);
/* ***End Layouts**** */

const Error = Loadable(
  lazy(() =>
    import("../views/authentication/Error").then(({ Error }) => ({
      default: Error,
    })),
  ),
);

/* ****Pages***** */

const Dashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/Dashboard/Dashbaord").then(({ Dashboard }) => ({
      default: Dashboard,
    })),
  ),
);

const Login = Loadable(
  lazy(() =>
    import("../views/authentication/Login").then(({ Login }) => ({
      default: Login,
    })),
  ),
);
const AccessDenied = Loadable(
  lazy(() =>
    import("../views/authentication/AccessDenied").then(({ AccessDenied }) => ({
      default: AccessDenied,
    })),
  ),
);

const ManageUser = Loadable(
  lazy(() =>
    import("../views/Profile/UsersList/ManageUser").then(({ ManageUser }) => ({
      default: ManageUser,
    })),
  ),
);

const SecurityDashboard = Loadable(
  lazy(() =>
    import("../views/Security/CreateSecurityGroup").then(
      ({ CreateSecurityGroup }) => ({ default: CreateSecurityGroup }),
    ),
  ),
);

const ListSecurityGroups = Loadable(
  lazy(() =>
    import("../views/Security/ListSecurityGroups").then(
      ({ ListSecurityGroups }) => ({ default: ListSecurityGroups }),
    ),
  ),
);
const ManageSite = Loadable(
  lazy(() =>
    import("../views/Profile/ManageSite/ManageSite").then(({ ManageSite }) => ({
      default: ManageSite,
    })),
  ),
);

const ProjectList = Loadable(
  lazy(() =>
    import("../views/project/ProjectList").then(({ ProjectList }) => ({
      default: ProjectList,
    })),
  ),
);
const ManageProject = Loadable(
  lazy(() =>
    import("../views/project/ManageProject").then(({ ManageProject }) => ({
      default: ManageProject,
    })),
  ),
);

const QuotesInvoice = Loadable(
  lazy(() =>
    import("../publicModules/QuotesInvoice/QuotesInvoice").then(
      ({ QuotesInvoice }) => ({
        default: QuotesInvoice,
      }),
    ),
  ),
);

const TaskBoard = Loadable(
  lazy(() =>
    import("../views/tasks/TaskBoard/TasksBoard").then(({ TaskBoard }) => ({
      default: TaskBoard,
    })),
  ),
);
const TaskList = Loadable(
  lazy(() =>
    import("../views/tasks/taskList/TaskList").then(({ TaskList }) => ({
      default: TaskList,
    })),
  ),
);
const ScheduleTaskList = Loadable(
  lazy(() =>
    import("../views/tasks/scheduleTask/ScheduleTaskList").then(
      ({ ScheduleTaskList }) => ({
        default: ScheduleTaskList,
      }),
    ),
  ),
);
const TaskCategoryList = Loadable(
  lazy(() =>
    import("../views/tasks/taskCategory/TaskCategoryList").then(
      ({ TaskCategoryList }) => ({
        default: TaskCategoryList,
      }),
    ),
  ),
);
const TaskCalender = Loadable(
  lazy(() =>
    import("../views/tasks/TaskCalender/TaskCalender").then(
      ({ TaskCalender }) => ({
        default: TaskCalender,
      }),
    ),
  ),
);

// expense module
const ExpenseList = Loadable(
  lazy(() =>
    import("../views/expenseModule/expense/ExpenseList").then(
      ({ ExpenseList }) => ({
        default: ExpenseList,
      }),
    ),
  ),
);
const ManageExpense = Loadable(
  lazy(() =>
    import("../views/expenseModule/expense/ManageExpense").then(
      ({ ManageExpense }) => ({
        default: ManageExpense,
      }),
    ),
  ),
);
// expense category
const ExpenseCategoryList = Loadable(
  lazy(() =>
    import("../views/expenseModule/category/ExpenseCategoryList").then(
      ({ ExpenseCategoryList }) => ({
        default: ExpenseCategoryList,
      }),
    ),
  ),
);
const ManageCategoryExpense = Loadable(
  lazy(() =>
    import("../views/expenseModule/category/ManageExpenseCategory").then(
      ({ ManageExpenseCategory }) => ({
        default: ManageExpenseCategory,
      }),
    ),
  ),
);
// expense report
const ExpenseReportList = Loadable(
  lazy(() =>
    import("../views/expenseModule/report/ExpenseReportList").then(
      ({ ExpenseReportList }) => ({
        default: ExpenseReportList,
      }),
    ),
  ),
);
const ManageReportExpense = Loadable(
  lazy(() =>
    import("../views/expenseModule/report/ManageExpenseReport").then(
      ({ ManageExpenseReport }) => ({
        default: ManageExpenseReport,
      }),
    ),
  ),
);
// department
const DepartmentList = Loadable(
  lazy(() =>
    import("../views/department/DepartmentList").then(({ DepartmentList }) => ({
      default: DepartmentList,
    })),
  ),
);
const ManageDepartment = Loadable(
  lazy(() =>
    import("../views/department/ManageDepartment").then(
      ({ ManageDepartment }) => ({
        default: ManageDepartment,
      }),
    ),
  ),
);

// approval
const ApprovalReportList = Loadable(
  lazy(() =>
    import("../views/approval/expenseReport/ApprovalExpenseReportList").then(
      ({ ApprovalExpenseReportList }) => ({
        default: ApprovalExpenseReportList,
      }),
    ),
  ),
);

// securtiy approval
const SecurityApprovalsList = Loadable(
  lazy(() =>
    import("../views/Security/approval/ApprovalList").then(
      ({ ApprovalList }) => ({
        default: ApprovalList,
      }),
    ),
  ),
);
const ManageSecuirtyApproval = Loadable(
  lazy(() =>
    import("../views/Security/approval/ManageApproval").then(
      ({ ManageApproval }) => ({
        default: ManageApproval,
      }),
    ),
  ),
);

export const Router = [
  {
    path: "/",

    element: (
      <ProtectedRoute>
        <FullLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        exact: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <Dashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/projects",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.PROJECT]}>
            <ProjectList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/project/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.PROJECT]}>
            <ManageProject />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/project/manage/:projectUUID",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.PROJECT]}>
            <ManageProject />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/taskboard",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASKBOARD]}>
            <TaskBoard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/task-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_LIST]}>
            <TaskList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/schedule-task",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SCHEDULE_TASK]}>
            <ScheduleTaskList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/task-category",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_CATEGORY]}>
            <TaskCategoryList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/task-calender",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASK_CALENDER]}>
            <TaskCalender />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <BeforeProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USER_BRANCH]}>
            <BranchList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USER_BRANCH]}>
            <ManageUserBranch />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch/manage/:branchUUId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USER_BRANCH]}>
            <ManageUserBranch />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/zone-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ZoneList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage/:userId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/list-security-groups",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <ListSecurityGroups />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-site",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.MANAGE_SITE]}>
            <ManageSite />
          </AuthorizedRoute>
        ),
      },

      // expense module
      {
        path: "/expense",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE]}>
            <ExpenseList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/expense/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE]}>
            <ManageExpense />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/expense/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE]}>
            <ManageExpense />
          </AuthorizedRoute>
        ),
      },
      // expense cateogry
      {
        path: "/expense-category",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE_CATEGORY]}>
            <ExpenseCategoryList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/expense-category/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE_CATEGORY]}>
            <ManageCategoryExpense />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/expense-category/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE_CATEGORY]}>
            <ManageCategoryExpense />
          </AuthorizedRoute>
        ),
      },
      // expense report
      {
        path: "/expense-report",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE_REPORT]}>
            <ExpenseReportList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/expense-report/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE_REPORT]}>
            <ManageReportExpense />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/expense-report/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE_REPORT]}>
            <ManageReportExpense />
          </AuthorizedRoute>
        ),
      },
      // expense report
      {
        path: "/department",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE_REPORT]}>
            <DepartmentList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/department/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE_REPORT]}>
            <ManageDepartment />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/department/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE_REPORT]}>
            <ManageDepartment />
          </AuthorizedRoute>
        ),
      },

      // approval
      {
        path: "/approval/report",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE_REPORT]}>
            <ApprovalReportList />
          </AuthorizedRoute>
        ),
      },

      // security
      {
        path: "/create-security-group",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-security-group/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/secuirty-role-duplicate/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard isDuplicate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/approvals-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityApprovalsList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/manage-approval",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <ManageSecuirtyApproval />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/manage-approval/:approvalId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <ManageSecuirtyApproval />
          </AuthorizedRoute>
        ),
      },
      { path: "access", element: <AccessDenied /> },
      { path: "error", element: <OOPSError /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
      { path: "/", element: <Navigate to="/dashboard" /> },
    ],
  },

  // --------------------------------TASK ROUTING----------------------------------------------

  {
    path: "/auth",
    element: (
      <PublicRoute>
        <BlankLayout />
      </PublicRoute>
    ),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/public/quotes/:quoteId",
    element: <QuotesInvoice />,
  },
];
