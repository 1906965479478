import { LoadState } from "../../constants/enums";
import { IManageSite, IManageSiteState } from "./manageSite.types";

export const initialManageSite: IManageSite = {
    manage_site_uuid: null,
    logo: null,
    site_name: null,
    login_title: null,
    status: "ACTIVE",
}

export const initialManageSiteState: IManageSiteState = {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    ManageSite: initialManageSite,
    ManageSiteLoading: LoadState.NotLoaded,
    error: null,
}