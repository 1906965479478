import { Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StandardCard } from "../../../components/Cards";
import LoadingDialog from "../../../components/Dialogs/LoadingDialog";
import { PageLoader } from "../../../components/PageLoader/PageLoader";
import { TextFieldWithLabel } from "../../../components/TextFieldWithLabel/TextFieldWithLabel";
import { PageContainer } from "../../../components/container/PageContainer";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { useDispatchWrapper } from "../../../hooks";
import { Breadcrumb } from "../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import {
  clearUserBranch,
  fetchUserBranchAsync,
  upsertUserBranchAysnc,
} from "../../../redux/DataManagement/dataManagementActions";
import { IStoreState } from "../../../redux/initialStoreState";
import { RoleBasedCustomButton } from "../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const ManageUserBranch = () => {
  const { branchUUId } = useParams() as { branchUUId?: string };
  const isUpdate = branchUUId ? true : false;
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/user-branch",
      title: "user-branch",
    },
    {
      title: isUpdate ? "Edit Branch" : "Create Branch",
    },
  ];
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const {
    data,
    error,
    loading: branchLoading,
  } = useSelector(
    (storeState: IStoreState) => storeState.dataManagement.branch,
  );
  const [loading, setLoading] = useState(false);

  const {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};

      return errors;
    },
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(
        upsertUserBranchAysnc(
          { ...values, branch_uuid: values.branch_uuid },
          (isSuccess) => {
            if (isSuccess) {
              navigate(`/user-branch`);
            }
            setLoading(false);
          },
        ),
      );
    },
  });

  useEffect(() => {
    setValues(data);
  }, [data]);
  useEffect(() => {
    if (branchUUId) {
      dispatch(fetchUserBranchAsync(branchUUId));
    }
  }, [branchUUId]);

  useEffect(() => {
    return () => {
      dispatch(clearUserBranch());
    };
  }, []);
  return (
    <PageLoader
      loading={branchLoading === LoadState.InProgress}
      error={error ? { message: error } : null}
    >
      <PageContainer
        title={isUpdate ? "Update Branch" : "Create Branch"}
        description="this is inner page"
      >
        <Breadcrumb title="Manage User Branch" items={BCrumb} />
        <StandardCard
          sx={{ ml: 2, mr: 2 }}
          heading={`${isUpdate ? "Update" : "Create"} Branch`}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldWithLabel
                  heading={"Branch Name"}
                  type="text"
                  id="branch_name"
                  fullWidth
                  value={values.branch_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldWithLabel
                  heading={"Address"}
                  type="text"
                  id="branch_address"
                  multiline
                  rows={2}
                  fullWidth
                  value={values.branch_address}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Stack direction={"row"} spacing={2} marginTop={4}>
              <RoleBasedCustomButton
                moduleId={MODULE_IDS.USERS}
                type="submit"
                disabled={loading}
                variant="contained"
              >
                Save
              </RoleBasedCustomButton>
              <LoadingDialog open={loading} />
            </Stack>
          </form>
        </StandardCard>
      </PageContainer>
    </PageLoader>
  );
};
