import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { manageSiteActions } from ".";
import { initialManageSiteState } from "./defaultState";
import { FETCH_MANAGE_SITE_PROGRESS, FETCH_MANAGE_SITE_SUCCESS, FETCH_MANAGE_SITE_FAILED, CLEAR_MANAGE_SITE_STATE, CLEAR_MANAGE_SITE } from "./ManageSiteActions";


export const manageSiteReducer = (
  state: IStoreState["manageSite"] = initialManageSiteState,
  action: manageSiteActions,
) => {
  switch (action.type) {
    // case FETCH_MANAGE_SITE_LIST_PROGRESS: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.loading = LoadState.InProgress;
    //   });
    //   return newState;
    // }
    // case FETCH_MANAGE_SITE_LIST_SUCCESS: {
    //   const { data, totalRecords } = action.payload;
    //   const newState = produce(state, (draftState) => {
    //     draftState.loading = LoadState.Loaded;
    //     draftState.list = data;
    //     draftState.totalRecords = totalRecords;
    //   });
    //   return newState;
    // }
    // case FETCH_MANAGE_SITE_LIST_FAILED: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.loading = LoadState.InProgress;
    //   });
    //   return newState;
    // }
    case FETCH_MANAGE_SITE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.ManageSiteLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_MANAGE_SITE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ManageSiteLoading = LoadState.Loaded;
        draftState.ManageSite = data;
      });
      return newState;
    }
    case FETCH_MANAGE_SITE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ManageSiteLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_MANAGE_SITE: {
      const newState = produce(state, (draftState) => {
        draftState.ManageSiteLoading = LoadState.NotLoaded;
        draftState.ManageSite = initialManageSiteState["ManageSite"];
      });
      return newState;
    }
    case CLEAR_MANAGE_SITE_STATE: {
      return initialManageSiteState;
    }
    default: {
      return state;
    }
  }
};
