import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { showMessage } from "../messages/messagesActions";
import { IProjectTeam, IProjectTeamState } from "./projectTeam.types";

export const FETCH_PROJECT_TEAM_LIST_PROGRESS =
  "FETCH_PROJECT_TEAM_LIST_PROGRESS";
export const FETCH_PROJECT_TEAM_LIST_SUCCESS =
  "FETCH_PROJECT_TEAM_LIST_SUCCESS";
export const FETCH_PROJECT_TEAM_LIST_FAILED = "FETCH_PROJECT_TEAM_LIST_FAILED";

export const fetchProjectTeamListProgress = () =>
  action(FETCH_PROJECT_TEAM_LIST_PROGRESS);
export const fetchProjectTeamListSuccess = (
  data: IProjectTeamState["list"],
  totalRecords: number,
) => action(FETCH_PROJECT_TEAM_LIST_SUCCESS, { data, totalRecords });
export const fetchProjectTeamListFailed = () =>
  action(FETCH_PROJECT_TEAM_LIST_FAILED);

export const fetchProjectTeamListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchProjectTeamListProgress());
        const searchQuery = getSearchQuery(queryParams);
        const res = await api.get(`/project/get-project-team${searchQuery}`);
        const data: IProjectTeam[] = res.data.data;
        const totalRecords = res.data.totalRecords;

        dispatch(fetchProjectTeamListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchProjectTeamListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const upsertProjectTeamAsync =
  (
    data: IProjectTeam,
    onCallback: (isSuccess: boolean, ProjectTeam?: IProjectTeam) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const { create_ts, insert_ts, rowId, ...rest } = data;
        const res = await api.post("/project/upsert-project-team", rest);
        let message = "Project Team saved successfully!";
        onCallback(true, res.data.data);
        dispatch(
          showMessage({
            type: "success",
            displayAs: "snackbar",
            message: message,
          }),
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_PROJECT_TEAM = "CLEAR_BANK_DETAILS";
export const clearProjectTeam = () => action(CLEAR_PROJECT_TEAM);
export const CLEAR_PROJECT_TEAM_STATE = "CLEAR_PROJECT_TEAM_STATE";
export const clearProjectTeamState = () => action(CLEAR_PROJECT_TEAM_STATE);
